module.exports = { 'seoList':
['/CMS/content/20287', '/CMS/content/20288', '/CMS/content/20289', '/', '/product/detail/1084', '/product/detail/1196', '/product/detail/128', '/product/detail/1348', '/product/detail/1349', '/product/detail/1350', '/product/detail/1351', '/product/detail/1352', '/product/detail/1353', '/product/detail/1354', '/product/detail/1355', '/product/detail/1356', '/product/detail/1357', '/product/detail/175', '/product/detail/176', '/product/detail/177', '/product/detail/178', '/product/detail/179', '/product/detail/180', '/product/detail/181', '/product/detail/185', '/product/detail/186', '/product/detail/187', '/product/detail/20', '/product/detail/200', '/product/detail/201', '/product/detail/21', '/product/detail/216', '/product/detail/22', '/product/detail/23', '/product/detail/28', '/product/detail/29', '/product/detail/30', '/product/detail/31', '/product/detail/318', '/product/detail/32', '/product/detail/324', '/product/detail/33', '/product/detail/34', '/product/detail/35', '/product/detail/363', '/product/detail/392', '/product/detail/393', '/product/detail/421', '/product/detail/548', '/product/detail/549', '/product/detail/550', '/product/detail/551', '/product/detail/556', '/product/detail/557', '/product/detail/558', '/product/detail/559', '/product/detail/617', '/product/detail/618', '/product/detail/619', '/product/detail/620', '/product/detail/621', '/product/detail/82', '/product/detail/83', '/product/detail/84', '/product/detail/85', '/product/detail/896'],
'seoItem': { 'cms20287': { 'keyword': '', 'description': '', 'title': '店主简介' },
  'cms20288': { 'keyword': '', 'description': '', 'title': '訂購須知' },
  'cms20289': { 'keyword': '', 'description': '', 'title': '聯係我們' },
  'home': { 'keyword': '型店，instore', 'description': '11111111111111111111' },
  'product1084': { 'keyword': '', 'description': '', 'title': '野生動物 - 熊貓' },
  'product1196': { 'keyword': null, 'description': null, 'title': '彩虹塔' },
  'product128': { 'keyword': null, 'description': null, 'title': '英文字母拼圖 - 大象 Elephant Puzzle                            ' },
  'product1348': { 'keyword': '', 'description': '', 'title': '魔術球' },
  'product1349': { 'keyword': '', 'description': '', 'title': '變形車組合' },
  'product1350': { 'keyword': '', 'description': '', 'title': '變形船組合' },
  'product1351': { 'keyword': '', 'description': '', 'title': '手偶公仔(獅子)' },
  'product1352': { 'keyword': '', 'description': '', 'title': '手偶公仔(班馬)' },
  'product1353': { 'keyword': '', 'description': '', 'title': '手偶公仔(海獅)' },
  'product1354': { 'keyword': '', 'description': '', 'title': '手偶公仔(海豚)' },
  'product1355': { 'keyword': '', 'description': '', 'title': '購物車' },
  'product1356': { 'keyword': '', 'description': '', 'title': '37塊彩色欅木積木' },
  'product1357': { 'keyword': '', 'description': '', 'title': '鼓' },
  'product175': { 'keyword': null, 'description': null, 'title': '沙鎚 Maracas - Hawaii                            ' },
  'product176': { 'keyword': null, 'description': null, 'title': '藍綠色沙鎚 Maracas - Blue and Green                            ' },
  'product177': { 'keyword': null, 'description': null, 'title': '甲蟲色沙鎚 Maracas- Beetle                            ' },
  'product178': { 'keyword': null, 'description': null, 'title': '彩色沙鎚 Maracas - Yellow+Orange+Green                            ' },
  'product179': { 'keyword': null, 'description': null, 'title': '紅藍綠沙鎚 Maracas - Red + Blue + Green Lined                            ' },
  'product180': { 'keyword': null, 'description': null, 'title': '星星沙鎚 Maracas + Little Star                            ' },
  'product181': { 'keyword': null, 'description': null, 'title': 'Castanets 響板                            ' },
  'product185': { 'keyword': null, 'description': null, 'title': '黃色響板 Clacker-Yellow                            ' },
  'product186': { 'keyword': null, 'description': null, 'title': '藍色響板 Clacker-Bule                            ' },
  'product187': { 'keyword': null, 'description': null, 'title': '長頸鹿鋼片琴 Xylophone                             ' },
  'product20': { 'keyword': null, 'description': null, 'title': '極地生物 Arctic Wolf                            ' },
  'product200': { 'keyword': '', 'description': '', 'title': '三項式 Trinomial Cube                            ' },
  'product201': { 'keyword': null, 'description': null, 'title': '圖畫拼圖 - 消防局 The story of Puzzle (Fireman)                            ' },
  'product21': { 'keyword': null, 'description': null, 'title': '極地生物 Emperor Penguin                            ' },
  'product216': { 'keyword': '', 'description': '', 'title': "迷你磅 PIKO Children's Scale Balance                            " },
  'product22': { 'keyword': null, 'description': null, 'title': '極地生物 Polar Bear                            ' },
  'product23': { 'keyword': '', 'description': '', 'title': '極地生物 Walrus                            ' },
  'product28': { 'keyword': null, 'description': null, 'title': '可愛動物 Hedgehog                            ' },
  'product29': { 'keyword': null, 'description': null, 'title': '可愛動物 Weasel                            ' },
  'product30': { 'keyword': null, 'description': null, 'title': '可愛動物 Koala                            ' },
  'product31': { 'keyword': null, 'description': null, 'title': '可愛動物 Chipmunk                             ' },
  'product318': { 'keyword': '', 'description': '', 'title': 'Magnetic Match Rings                            ' },
  'product32': { 'keyword': null, 'description': null, 'title': '海洋生物 Green Turtle                            ' },
  'product324': { 'keyword': '', 'description': '', 'title': '二項式 Binomial Cube                            ' },
  'product33': { 'keyword': null, 'description': null, 'title': '海洋生物 Clown Crab                            ' },
  'product34': { 'keyword': null, 'description': null, 'title': '海洋生物 Chambered Nautilus                            ' },
  'product35': { 'keyword': null, 'description': null, 'title': '海洋生物 Octopus Siamensis                            ' },
  'product363': { 'keyword': '', 'description': '', 'title': '數字時鐘 Fun Digital Clock                            ' },
  'product392': { 'keyword': '', 'description': '', 'title': '八爪魚積木拼圖 Octopus Puzzle                            ' },
  'product393': { 'keyword': '', 'description': '', 'title': '大象積木拼圖 Big Elephant Puzzle                            ' },
  'product421': { 'keyword': '', 'description': '', 'title': '數字拼圖 See Inside Numbers Puzzle                             ' },
  'product548': { 'keyword': null, 'description': null, 'title': '冰河世紀動物 - 大地懶' },
  'product549': { 'keyword': null, 'description': null, 'title': '冰河世紀動物 - 劍齒虎' },
  'product550': { 'keyword': null, 'description': null, 'title': '冰河世紀動物 - 長毛象' },
  'product551': { 'keyword': null, 'description': null, 'title': '冰河世紀動物 - 長毛犀牛' },
  'product556': { 'keyword': null, 'description': null, 'title': '野生動物 - 豹' },
  'product557': { 'keyword': '', 'description': '', 'title': '野生動物 - 長頸鹿' },
  'product558': { 'keyword': '', 'description': '', 'title': '野生動物 - 河馬' },
  'product559': { 'keyword': null, 'description': null, 'title': '野生動物 - 班馬' },
  'product617': { 'keyword': null, 'description': null, 'title': '北美動物' },
  'product618': { 'keyword': '', 'description': '', 'title': '海洋生物' },
  'product619': { 'keyword': null, 'description': null, 'title': '北美麋' },
  'product620': { 'keyword': null, 'description': null, 'title': '鷹' },
  'product621': { 'keyword': null, 'description': null, 'title': '狼' },
  'product82': { 'keyword': null, 'description': null, 'title': '太空漫遊系列 Astronaut                            ' },
  'product83': { 'keyword': null, 'description': null, 'title': '太空漫遊系列 Command Module Rocket                            ' },
  'product84': { 'keyword': null, 'description': null, 'title': '太空漫遊系列 Lunar Lander                             ' },
  'product85': { 'keyword': null, 'description': null, 'title': '太空漫遊系列 Saturn V                            ' },
  'product896': { 'keyword': '3米彩虹傘', 'description': '3米彩虹傘', 'title': '彩虹傘 (3米)' } } };
