

















import { Component, Prop, Vue } from 'vue-property-decorator';
import api from '@/sdk/api/Api.ts';
@Component({
  components: {
    HomeBanner: () => import('@/components/base/pc/InsBanner.vue'),
    // HomeProduct: () => import('@/components/business/pc/home/InsLatestProduct.vue'),
    // HomeAbout: () => import('@/components/business/pc/home/InsAboutUS.vue'),
    // HomeVideo: () => import('@/components/business/pc/home/InsVideo.vue'),
    // HomeFacebook: () => import('@/components/business/pc/home/InsFacebook.vue'),
    // HomeContact: () => import('@/components/business/pc/home/InsContactUS.vue'),
    // HomeMap: () => import('@/components/business/pc/home/InsMap.vue'),
    // Promotion: () => import('@/components/business/pc/promotion/InsPromotion.vue'),
    EdCatalogs: () => import('@/components/edBusiness/pc/home/EdCatalogs.vue'),
    EdPromotion: () => import('@/components/edBusiness/pc/home/EdCatalogsPromotion.vue'),
    EdProductPromotion: () => import('@/components/edBusiness/pc/home/EdProductPromotion.vue'),
    EdMonterssori: () => import('@/components/edBusiness/pc/home/EdMonterssori.vue')
  }
})
export default class InsHome extends Vue {
  created () {
    let keywords = document.createElement('meta');
    keywords.setAttribute('name', 'keywords');
    keywords.setAttribute('content', require('../../sdk/common/SeoData').seoItem['home'].keyword);
    document.head.appendChild(keywords);
    let description = document.createElement('meta');
    description.setAttribute('name', 'description');
    description.setAttribute('content', require('../../sdk/common/SeoData').seoItem['home'].description);
    document.head.appendChild(description);
    document.dispatchEvent(new Event('render-event'));
  }
  mounted () {
    // api
    //   .getData(1, 1)
    //   .then((res: any) => {
    //     var _this = this;
    //     this.$HiddenLayer();
    //   })
    //   .catch((err: any) => {
    //     // 请求失败后的处理函数
    //     console.log(err);
    //   });
  }
}
